window.GenerateYin = function GenerateYin() {

  var self = this
  self.minuteFactor = 60
  self.minutes = 16
  self.targetTime = self.minutes*self.minuteFactor  // 14 minutes
  self.STARTS = ['start_a', 'start_b']
  self.NEXTS = ['next_a', 'next_b', 'next_d', 'next_e', 'next_f']
  self.TIMES = ['1_min_a', '1_min_b', '2_min_a', '2_min_b', '2_min_c', '2_min_d', '2_min_e', '2_min_f', '3_min_a', '3_min_b', '4_min_a', '4_min_b']
  self.OTHER_SIDES = ['other_side_a', 'other_side_b', 'other_side_c', 'other_side_d']
  self.HALFWAYS = ['halfway_a', 'halfway_c', 'halfway_d', 'halfway_e', 'halfway_g', 'halfway_i']
  self.REBOUNDS = ['rebound_a', 'rebound_b']
  self.EXITS = ['exit_a', 'exit_b', 'exit_c', 'exit_d']
  self.SILENCES = ['silence_1', 'silence_2', 'silence_4']
  self.POSES = {
    'bound_angle': {
      title: "", image: "", day_permutation: [1,0,1,0,1,0,0], day: 1, minutes: [2,2,3,4], pair: false, prevent: ['frog', 'standing_straddle'], routine_with: [], repeatable: true, groups: ['lower_back', 'groin']
    },
    'childs_pose': {
      title: "", image: "", day_permutation: [0,1,0,1,0,1,0], day: 1, minutes: [2,2,3], pair: false, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders']
    },
    'cross_shin': {
      title: "", image: "", day_permutation: [0,0,1,0,1,0,1], day: 1, minutes: [2,2,3,4], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'glutes']
    },
    'down_dog': {
      title: "", image: "", day_permutation: [1,0,0,1,0,1,0], day: 1, minutes: [1], rare: true, pair: false, prevent: ['forward_fold', 'half_front_split', 'single_leg_forward_fold'], routine_with: [], not_last: true, repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring']
    },
    'dragon': {
      title: "", image: "", day_permutation: [0,1,0,0,1,0,1], day: 0, minutes: [2,3], pair: true, prevent: ['low_dragon'], routine_with: [], repeatable: true, groups: ['quads', 'groin']
    },
    'low_dragon': {
      title: "", image: "", day_permutation: [1,0,1,0,0,1,0], day: 1, minutes: [2,3], pair: true, prevent: ['dragon'], routine_with: [], repeatable: true, groups: ['calves', 'quads']
    },
    'forward_fold': {
      title: "", image: "", day_permutation: [0,1,0,1,0,0,1], day: 1, minutes: [2,2,3], pair: false, prevent: ['down_dog', 'half_front_split', 'single_leg_forward_fold'], routine_with: [], repeatable: true, groups: ['lower_back', 'hamstring']
    },
    'fragon': {
      title: "", image: "", day_permutation: [1,0,1,0,1,0,0], day: 1, minutes: [1,2], rare: true, pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['groin', 'quads']
    },
    'frog': {
      title: "", image: "", day_permutation: [0,1,0,1,0,1,0], day: 0, minutes: [2,2,3], pair: false, prevent: ['bound_angle'], routine_with: [], repeatable: true, groups: ['groin']
    },
    'full_saddle': {
      title: "", image: "", day_permutation: [0,0,1,0,1,0,1], day: 1, minutes: [2,2,2,3,4], pair: false, prevent: [], routine_with: [], repeatable: true, groups: ['quads', 'lower_back']
    },
    'half_front_split': {
      title: "", image: "", day_permutation: [1,0,0,1,0,1,0], day: 1, minutes: [2], pair: true, prevent: ['down_dog', 'forward_fold', 'single_leg_forward_fold'], routine_with: [], repeatable: true, not_last: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'happy_baby': {
      title: "", image: "", day_permutation: [0,1,0,0,1,0,1], day: 1, minutes: [1,2], rare: true, pair: false, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'lizard': {
      title: "", image: "", day_permutation: [1,0,1,0,0,1,0], day: 1, minutes: [2,2,3], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'pigeon_pose': {
      title: "", image: "", day_permutation: [0,1,0,1,0,0,1], day: 0, minutes: [2,2,3], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'puppy_dog': {
      title: "", image: "", day_permutation: [1,0,1,0,1,0,0], day: 0, minutes: [1,1,2], pair: false, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'saddle_archer': {
      title: "", image: "", day_permutation: [0,1,0,1,0,1,0], day: 0, minutes: [1], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'saddle_eagle': {
      title: "", image: "", day_permutation: [0,0,1,0,1,0,1], day: 0, minutes: [1,1,2], pair: true, prevent: ['thread_the_needle'], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'seated_straddle': {
      title: "", image: "", day_permutation: [1,0,0,1,0,1,0], day: 0, minutes: [2,2,2,3], pair: false, prevent: ['standing_straddle'], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'seal': {
      title: "", image: "", day_permutation: [0,1,0,0,1,0,1], day: 0, minutes: [1,1,2], pair: false, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back']
    },
    'single_leg_forward_fold': {
      title: "", image: "", day_permutation: [1,0,1,0,0,1,0], day: 0, minutes: [2,2,3], pair: true, prevent: ['down_dog', 'forward_fold', 'half_front_split'], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'single_leg_saddle': {
      title: "", image: "", day_permutation: [0,1,0,1,0,0,1], day: 0, minutes: [1,2], pair: true, prevent: [], routine_with: ['saddle_eagle', 'saddle_archer', 'saddle'], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'standing_straddle': {
      title: "", image: "", day_permutation: [1,0,1,0,1,0,0], day: 0, minutes: [1,2,2,3], pair: false, prevent: ['seated_straddle', 'bound_angle', 'frog'], routine_with: [], repeatable: true, not_last: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'sumo_squat': {
      title: "", image: "", day_permutation: [0,1,0,1,0,1,0], day: 0, minutes: [1,2,2,3], rare: true, pair: false, prevent: [], routine_with: [], repeatable: true, not_last: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'supine_twist': {
      title: "", image: "", day_permutation: [0,0,1,0,1,0,1], day: 0, minutes: [1], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'thread_the_needle': {
      title: "", image: "", day_permutation: [1,0,0,1,0,1,0], day: 0, minutes: [1,2,2], pair: true, prevent: ['saddle_eagle'], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'twisted_cross': {
      title: "", image: "", day_permutation: [0,1,0,0,1,0,1], day: 0, minutes: [2,2], pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    },
    'twisted_lizard': {
      title: "", image: "", day_permutation: [1,0,1,0,0,1,0], day: 0, minutes: [1], rare: true, pair: true, prevent: [], routine_with: [], repeatable: true, groups: ['lower_back', 'upper_back', 'shoulders', 'hamstring', 'glutes', 'groin']
    }
  }

  self.generate = function(){

    var dayIndex = (new Date()).getDay()
    var potentialPoseKeys = Object.keys(self.POSES).filter(x => {
      var v = self.POSES[x]
      if (v.day_permutation[dayIndex] == 1 && (!v.rare || (v.rare && [1,2].sample() == 1))){
        return x
      }
    })
    var potential_poses = {}
    potentialPoseKeys.forEach(x => {
      potential_poses[x] = self.POSES[x]
    })

    //filter to poses on current day and 25% chance of inclusion if rare
    // var potential_poses = self.POSES.to_a.select{|x| x[1][:day] == Date.today.yday%2 && (x[1][:rare].nil? || (x[1][:rare] && [1,2,2,2].sample == 1)) }.to_h
    var stack = []
    var dont_do_again = []
    var first = true
    var time_elapsed = 1

    while(time_elapsed < self.targetTime){
      // #begin or transition
      if(first){
        stack.push({time: time_elapsed, source: self.STARTS.sample()})
        first = false
      }else{
        stack.push({time: time_elapsed-7, source: self.EXITS.sample() })
        stack.push({time: time_elapsed, source: self.NEXTS.sample()})
      }

      var pose = Object.keys(potential_poses).sample()
      var pose_config = potential_poses[pose]

      while(dont_do_again.includes(pose)){
        pose = Object.keys(potential_poses).sample()
        pose_config = potential_poses[pose]
      }
      dont_do_again = dont_do_again.concat([pose].concat(pose_config.prevent))

      var how_long_minutes = pose_config.minutes.sample()
      var how_long_seconds = how_long_minutes*self.minuteFactor

      stack.push({time: time_elapsed, source: pose, pose: true, title: pose_config.title, length: how_long_minutes, pair: pose_config.pair })

      stack.push({time: time_elapsed, source: self.SILENCES.sample() }) //#some silence makes it more natural)

      // console.log(self.TIMES.filter(x => x.contains( how_long_minutes.toString() ) ))
      // self.TIMES.filter(x => x.contains( how_long_minutes.toString() ) ).sample()
      stack.push({time: time_elapsed, source: self.TIMES.filter(x => x.includes( how_long_minutes.toString() ) ).sample() } )
      // // # if the time
      if(how_long_minutes > 1){
        //#add a half way point
        stack.push({time: time_elapsed+(how_long_seconds/2), source: self.HALFWAYS.sample()})
      }

      // // # increment time_elapsed by increment by
      time_elapsed += how_long_seconds

      if(pose_config.pair){
        stack.push({time: time_elapsed, source: self.OTHER_SIDES.sample() })
        stack.push({time: time_elapsed, source: self.TIMES.filter(x => x.includes( how_long_minutes.toString() ) ).sample() })
        if(how_long_minutes > 1 && [1,2,2].sample() == 1 ){ //sometimes say how long
          // #add a half way point
          stack.push({time: time_elapsed+(how_long_seconds/2), source: self.HALFWAYS.sample() })
        }

        time_elapsed += how_long_seconds
      }


    }

    // #add rebound at time_elapsed
    stack.push({time: time_elapsed, source: self.REBOUNDS.sample() })

    //find last pose == true and make sure doesnt have not_last flag
    var stackCopy = [...stack]
    var last_pose = stackCopy.reverse().find(x => x.pose ).source

    if(time_elapsed != self.targetTime+1 || self.POSES[last_pose].not_last){
      return self.generate()
    }else{
      return stack.sort((a, b) => a.time - b.time)
    }

  }

  self.generateDaily = function(){
    //get current day of month, lookup routine and generate from that
    var monthDay = (new Date()).getDate()
    var routine = self.routines[monthDay]
    var expansion = []
    var time_elapsed = 0
    var first = true

    routine.forEach(p => {
      var pose = p[0]
      var length = p[1]
      var result = self.generateExpansion(pose, length, first, time_elapsed)
      time_elapsed = result.time_elapsed
      expansion = expansion.concat(result.expansion)
      if(first){
        first = false
      }
    })

    //add rebound
    expansion.push({time: time_elapsed-7, source: self.EXITS.sample() })
    expansion.push({time: time_elapsed, source: self.REBOUNDS.sample() })

    return expansion.sort((a, b) => a.time - b.time)
  }

  self.generateExpansion = function(pose, length, first, time_elapsed){
    var stack = []
    var time_elapsed = time_elapsed

    if(first){
      stack.push({time: time_elapsed, source: self.STARTS.sample()})
    }else{
      if(time_elapsed-7 > 0){
        stack.push({time: time_elapsed-7, source: self.EXITS.sample() })
      }
      stack.push({time: time_elapsed, source: self.NEXTS.sample()})
    }

    var how_long_minutes = length
    var how_long_seconds = length*self.minuteFactor
    console.log(self.POSES, pose)
    var pose_config = self.POSES[pose]

    stack.push({time: time_elapsed, source: pose, pose: true, title: pose_config.title, length: how_long_minutes, pair: pose_config.pair })
    stack.push({time: time_elapsed, source: self.SILENCES.sample() }) //#some silence makes it more natural)

    // console.log(self.TIMES.filter(x => x.contains( how_long_minutes.toString() ) ))
    // self.TIMES.filter(x => x.contains( how_long_minutes.toString() ) ).sample()
    stack.push({time: time_elapsed, source: self.TIMES.filter(x => x.includes( length.toString() ) ).sample() } )
    // // # if the time
    if(length > 1){
      //#add a half way point
      stack.push({time: time_elapsed+(how_long_seconds/2), source: self.HALFWAYS.sample()})
    }

    // // # increment time_elapsed by increment by
    time_elapsed += how_long_seconds

    if(pose_config.pair){
      stack.push({time: time_elapsed, source: self.OTHER_SIDES.sample() })
      stack.push({time: time_elapsed, source: self.TIMES.filter(x => x.includes( length.toString() ) ).sample() })
      if(length > 1 && [1,2,2].sample() == 1 ){ //sometimes say how long
        // #add a half way point
        stack.push({time: time_elapsed+(how_long_seconds/2), source: self.HALFWAYS.sample() })
      }

      time_elapsed += how_long_seconds
    }

    return {expansion: stack, time_elapsed: time_elapsed}
  }

  self.routines = { //account for repeats, "This is a repeat, this is a repeat position"
    29: [
      ["puppy_dog", 2],
      ["full_saddle", 4],
      ["seated_straddle", 4],
      ["frog", 4]
    ],
    30: [
      ["supine_twist", 2],
      ["pigeon_pose", 3],
      ["single_leg_forward_fold", 3]
    ]
  }

//Puppy DogSaddleSeated StraddleFrog (saturday)
//Supine TwistPigeonSingle Leg Forward Fold
//SaddleSeated Forward FoldBound AngleSeated Straddle (monday)
//SaddleBound AngleSeated Straddle (3 poses for 3 minutes)
//Saddle Archer Puppy Dog Dragon Lizard
//SaddleHalf SaddleBound AngleSeated StraddlePigeonBreathing WorkSingle Leg Forward Fold
//Low DragonSupine LizardSupine TwistPigeon


}
