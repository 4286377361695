
window.Yin = function Yin() {

  var self = this
  self.elapsedSeconds = 0
  self.maxSeconds = 1200 //20 minutes
  self.clock = null
  self.paused = true
  self.triggerMap = {}
  self.currentlyPlaying = {}
  self.firstStart = true
  self.soundLookup = {}
  self.backgroundMusicIndex = 0
  self.noSleep = new NoSleep()
  self.currentBackgroundSong = null

  // self.backgroundMusic = {
  //   'background_a': require('../sounds/background_a.mp3'),
  //   'background_b': require('../sounds/background_b.mp3'),
  //   'background_c': require('../sounds/background_c.mp3'),
  //   'background_d': require('../sounds/background_d.mp3'),
  //   'background_e': require('../sounds/background_e.mp3'),
  //   'background_f': require('../sounds/background_f.mp3'),
  //   'background_g': require('../sounds/background_g.mp3'),
  //   'background_h': require('../sounds/background_h.mp3'),
  //   'background_i': require('../sounds/background_i.mp3'),
  //   'background_j': require('../sounds/background_j.mp3'),
  //   'background_k': require('../sounds/background_k.mp3'),
  //   'background_l': require('../sounds/background_l.mp3')
  // }

  self.backgroundMusic = {
    'background_m': require('../sounds/background_m.mp3'),
    'background_n': require('../sounds/background_n.mp3'),
    'background_o': require('../sounds/background_o.mp3'),
    'background_p': require('../sounds/background_p.mp3')
  }

  self.shuffledKeys = shuffle(Object.keys(self.backgroundMusic))

  self.audioMap = {
    '1_min_a': require('../sounds/1_min_a.mp3'),
    '1_min_b': require('../sounds/1_min_b.mp3'),
    '2_min_a': require('../sounds/2_min_a.mp3'),
    '2_min_b': require('../sounds/2_min_b.mp3'),
    '2_min_c': require('../sounds/2_min_c.mp3'),
    '2_min_d': require('../sounds/2_min_d.mp3'),
    '2_min_e': require('../sounds/2_min_e.mp3'),
    '2_min_f': require('../sounds/2_min_f.mp3'),
    '3_min_a': require('../sounds/3_min_a.mp3'),
    '3_min_b': require('../sounds/3_min_b.mp3'),
    '4_min_a': require('../sounds/4_min_a.mp3'),
    '4_min_b': require('../sounds/4_min_b.mp3'),
    'bound_angle': require('../sounds/bound_angle.mp3'),
    'childs_pose': require('../sounds/childs_pose.mp3'),
    'cross_shin': require('../sounds/cross_shin.mp3'),
    'down_dog': require('../sounds/down_dog.mp3'),
    'dragon': require('../sounds/dragon.mp3'),
    'exit_a': require('../sounds/exit_a.mp3'),
    'exit_b': require('../sounds/exit_b.mp3'),
    'exit_c': require('../sounds/exit_c.mp3'),
    'exit_d': require('../sounds/exit_d.mp3'),
    'extended_dragon': require('../sounds/extended_dragon.mp3'),
    'forward_fold': require('../sounds/forward_fold.mp3'),
    'fragon': require('../sounds/fragon.mp3'),
    'frog': require('../sounds/frog.mp3'),
    'full_saddle': require('../sounds/full_saddle.mp3'),
    'half_front_split': require('../sounds/half_front_split.mp3'),
    'halfway_a': require('../sounds/halfway_a.mp3'),
    'halfway_c': require('../sounds/halfway_c.mp3'),
    'halfway_d': require('../sounds/halfway_d.mp3'),
    'halfway_e': require('../sounds/halfway_e.mp3'),
    'halfway_g': require('../sounds/halfway_g.mp3'),
    'halfway_i': require('../sounds/halfway_i.mp3'),
    'happy_baby': require('../sounds/happy_baby.mp3'),
    'lizard': require('../sounds/lizard.mp3'),
    'low_dragon': require('../sounds/low_dragon.mp3'),
    'next_a': require('../sounds/next_a.mp3'),
    'next_b': require('../sounds/next_b.mp3'),
    'next_d': require('../sounds/next_d.mp3'),
    'next_e': require('../sounds/next_e.mp3'),
    'next_f': require('../sounds/next_f.mp3'),
    'other_side_a': require('../sounds/other_side_a.mp3'),
    'other_side_b': require('../sounds/other_side_b.mp3'),
    'other_side_c': require('../sounds/other_side_c.mp3'),
    'other_side_d': require('../sounds/other_side_d.mp3'),
    'pigeon_pose': require('../sounds/pigeon_pose.mp3'),
    'puppy_dog': require('../sounds/puppy_dog.mp3'),
    'rebound_a': require('../sounds/rebound_a.mp3'),
    'rebound_b': require('../sounds/rebound_b.mp3'),
    'saddle_archer': require('../sounds/saddle_archer.mp3'),
    'saddle_eagle': require('../sounds/saddle_eagle.mp3'),
    'seal': require('../sounds/seal.mp3'),
    'seated_straddle': require('../sounds/seated_straddle.mp3'),
    'silence_1': require('../sounds/silence_1.mp3'),
    'silence_2': require('../sounds/silence_2.mp3'),
    'silence_4': require('../sounds/silence_4.mp3'),
    'single_leg_forward_fold': require('../sounds/single_leg_forward_fold.mp3'),
    'single_leg_saddle': require('../sounds/single_leg_saddle.mp3'),
    'standing_straddle': require('../sounds/standing_straddle.mp3'),
    'start_a': require('../sounds/start_a.mp3'),
    'start_b': require('../sounds/start_b.mp3'),
    'start_c': require('../sounds/start_c.mp3'),
    'start_d': require('../sounds/start_d.mp3'),
    'start_e': require('../sounds/start_e.mp3'),
    'sumo_squat': require('../sounds/sumo_squat.mp3'),
    'supine_twist': require('../sounds/supine_twist.mp3'),
    'thread_the_needle': require('../sounds/thread_the_needle.mp3'),
    'twisted_cross': require('../sounds/twisted_cross.mp3'),
    'twisted_lizard': require('../sounds/twisted_lizard.mp3')
  }

  self.init = function(){

    $('[data-refresh-trigger]').on('click', function(){
      location.reload()
    })

    $('[data-play-trigger]').on('click', function(){
      $('[data-pause-trigger]').show()
      $('[data-play-trigger]').hide()
      self.paused = false
      self.noSleep.enable()

      //resume sounds currently playing
      Object.keys(self.currentlyPlaying).forEach(x => {
        self.currentlyPlaying[x].play()
      })

      if(self.firstStart){
        self.music()
      }

    })

    $('[data-pause-trigger]').on('click', function(){
      $('[data-play-trigger]').show()
      $('[data-pause-trigger]').hide()
      self.paused = true
      self.noSleep.disable()

      //pause sounds currently playing
      Object.keys(self.currentlyPlaying).forEach(x => {
        self.currentlyPlaying[x].pause()
      })
    })

    var sounds = []

    $('[data-audio-track]').each(function(i, elm){
      var elm = $(elm)
      sounds.push(elm.data('audioSource'))
      if(self.triggerMap[elm.data('audioTime')]){
        var arr = self.triggerMap[elm.data('audioTime')]
        arr.push(elm.data('audioSource'))
        self.triggerMap[elm.data('audioTime')] = arr
      }else{
        self.triggerMap[elm.data('audioTime')] = [elm.data('audioSource')]
      }
    })

    // console.log(self.audioMap, self.triggerMap)

    sounds.forEach(x=> {
      if(!self.soundLookup[x]){
        self.soundLookup[x] = new Howl({src: [self.audioMap[x]], preload: true})
      }
    })


    self.clock = setInterval(self.tick, 1000)

  }

  self.play = function(soundStr, nextQueue){
    if(Object.keys(self.currentlyPlaying).length>2){
      return null
    }
    var sound = self.soundLookup[soundStr]
    var id = sound.play() //triggered
    self.currentlyPlaying[id] = sound
    sound.once('end', function(id){ //once makes it clear after, preventing double play
      delete self.currentlyPlaying[id]
      //trigger next in trigger map if present
      if(nextQueue.length > 0){
        self.play(nextQueue[0], nextQueue.slice(1, 100) )
      }
    })


    self.highlightIfPose(soundStr)

  }

  self.highlightIfPose = function(str){
    var elm = $('[data-pose-key="'+str+'"]')
    if(elm.length > 0){
      $('[data-pose-key]').css('color', 'white').promise().done(function(){
        elm.css('color', '#f8f8a7')
      })
    }
  }

  self.tick = function(){
    if(!self.paused){
      self.elapsedSeconds += 1
      //if done, reset it
      if(self.elapsedSeconds >= self.maxSeconds){
        //reset
        self.elapsedSeconds = 0
        self.firstStart = true
        $('[data-pause-trigger]').trigger('click')
      }

      if(self.elapsedSeconds+10 == self.maxSeconds){
        self.currentBackgroundSong.fade(0.6, 0.0, 600)
      }

      //show timer, possibly progress bar
      var minutes = Math.floor(self.elapsedSeconds / 60)
      var seconds = self.elapsedSeconds % 60
      $('[data-timer]').html(minutes.toString().padStart(2, '0')+':'+seconds.toString().padStart(2, '0'))

      // //trigger sounds if time to
      if(self.triggerMap[self.elapsedSeconds]){
      //   //if there are multiple on the same second, chain them in order
        var soundStr = self.triggerMap[self.elapsedSeconds][0]
        if(self.triggerMap[self.elapsedSeconds].length != 0){
          self.play(self.triggerMap[self.elapsedSeconds][0], self.triggerMap[self.elapsedSeconds].slice(1, 100) )
        }
      }

    }
  }

  self.music = function(){
    //self.maxSeconds worth of music
    //shuffle, load and stream, then fade it out for 10 seconds at 19:50
    //start bg music
    if(!self.paused){
      var src = self.backgroundMusic[self.shuffledKeys[self.backgroundMusicIndex]]
      var sound = new Howl({src: [src], html5: true, volume: 0.6})
      self.currentBackgroundSong = sound
      var id = sound.play() //triggered
      sound.once('end', function(id){ //once makes it clear after, preventing double play
        delete self.currentlyPlaying[id]
        self.music()//chain forward next
      })
      self.backgroundMusicIndex += 1
      self.currentlyPlaying[id] = sound
      self.firstStart = false
    }

  }

}
