require('normalize.css/normalize.css');
require('./styles/index.scss');

import NoSleep from "nosleep.js"
window.NoSleep = NoSleep

import $ from "jquery";
import JQuery from 'jquery'
window.$ = JQuery

import {Howl, Howler} from 'howler';
window.Howl = Howl
window.Howler = Howler


//monkey patches
Array.prototype.sample = function () {
  return this[Math.floor(Math.random() * this.length)]
}

String.prototype.titleize = function() {
  var sentence = this
  sentence = sentence.replace(/_/g, ' ').replace(/-/g, ' ')
  if(!sentence.split) return sentence;
  var _titleizeWord = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  },
  result = [];
  sentence.split(" ").forEach(function(w) {
    result.push(_titleizeWord(w));
  });
  return result.join(" ");
}

window.shuffle = function(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}


function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('./modules/', true, /\.js$/))
