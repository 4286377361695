

var initYin = function(){
  var generateYin = new GenerateYin()
  var generated = generateYin.generate()

  for(var i = 0; i<generated.length; i++){
    var elm = generated[i]

    var html = '<span style="display: none;" data-audio-track data-audio-source="'+elm.source+'" data-audio-time="'+elm.time+'"></span>'
    $('[data-track-container]').append(html)

    if(elm.pose){
      var html = '<h2 data-pose-key="'+elm.source+'">'+elm.source.titleize()+' - '+elm.length+'m'
      if(elm.pair){
        html += " (R/L)"
      }
      html += "</h2>"
      $('[data-pose-container]').append(html)
    }

  }

  var yin = new Yin()
  yin.init()
}

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    // User is signed in.
    $("#logged_in").show()
    $("#not_logged_in").hide()

    console.log(user)

    initYin()
    //request for secured data to be loaded into restricted portion of app

  }else{
    $("#not_logged_in").show()
    $("#logged_in").hide()
  }
});
//check if logged in already to determine what view to display, if to redirect to authed route

$('[data-trigger="sign_out"]').on('click', e => {
  firebase.auth().signOut()
})


$('[data-trigger="sign_up"]').on('click', e => {

  var email = $('#sign_up').find('.email').val()
  var pw = $('#sign_up').find('.password').val()

  firebase.auth().signInWithEmailAndPassword(email, pw).catch(function(error) {
    // Handle Errors here.
    var errorCodeIN = error.code;
    var errorMessageIN = error.message;
    alert(errorMessageIN)
    // ...
  }).then( (x) => {
    if(x){
      console.log('logged in', x)
      //store and redirect to authed page
    }
  })

})
